<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4">
        <v-card>
          <v-img
            class="resize2"
            height="280"
            src="../assets/img/us2.jpg"
            lazy-src="../assets/img/us2.jpg"
          ></v-img>
          <v-card-title>United States</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4">
        <v-card>
          <v-img
            class="resize2"
            height="280"
            src="../assets/img/Italy.jpg"
            lazy-src="../assets/img/Italy.jpg"
          ></v-img>
          <v-card-title>Italy</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" sm="4" md="4" lg="4">
        <v-card>
          <v-img
            class="resize2"
            height="280"
            src="../assets/img/kolkata1.jpg"
            lazy-src="../assets/img/kolkata1.jpg"
          ></v-img>
          <v-card-title>India</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
