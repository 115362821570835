<template>
  <div>
    <v-row v-if="availWidth > 600">
      <v-col cols="6">
        <div id="app">
          <v-app class="inspire">
            <v-card class="mx-auto resize4">
              <v-img
                :src="firstAsset"
                :lazy-src="firstAsset"
                class="resize4 resizenew"
                aspect-ratio="1:4"
                eager
              ></v-img>
            </v-card>
          </v-app>
        </div>
      </v-col>
      <v-col cols="6" v-if="totalPackageImages.length === 0">
        <v-row>
          <v-col cols="6">
            <div id="app">
              <v-app class="inspire">
                <v-card class="mx-auto resize4">
                  <v-img
                    src="../assets/img/pic2.jpg"
                    lazy-src="../assets/img/pic2.jpg"
                    class="resize4"
                    aspect-ratio="1:4"
                    eager
                  ></v-img>
                </v-card>
              </v-app>
            </div>
          </v-col>
          <v-col cols="6">
            <div id="app">
              <v-app class="inspire">
                <v-card class="mx-auto resize4">
                  <v-img
                    src="../assets/img/pic6.jpg"
                    lazy-src="../assets/img/pic6.jpg"
                    class="resize4"
                    aspect-ratio="1:4"
                    eager
                  ></v-img>
                </v-card>
              </v-app>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <div id="app">
              <v-app id="inspire">
                <v-card class="mx-auto resize4">
                  <v-img
                    src="../assets/img/pic1.jpg"
                    lazy-src="../assets/img/pic1.jpg"
                    class="resize4"
                    aspect-ratio="1:4"
                    eager
                  ></v-img>
                </v-card>
              </v-app>
            </div>
          </v-col>
          <v-col cols="6">
            <div id="app">
              <v-app id="inspire">
                <template>
                  <v-card class="mx-auto resize4">
                    <v-img
                      src="../assets/img/pic2.jpg"
                      lazy-src="../assets/img/pic2.jpg"
                      class="resize4"
                      aspect-ratio="1:4"
                      eager
                    ></v-img>
                  </v-card>
                </template>
              </v-app>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="6" v-else>
        <v-row>
          <v-col
            cols="6"
            v-for="(image, index) of totalPackageImages"
            :key="index"
          >
            <div id="app" v-if="index <= 3">
              <v-app class="inspire">
                <v-card class="mx-auto resize4">
                  <v-img
                    :src="image.asset_url"
                    :lazy-src="image.asset_url"
                    class="resize4"
                    aspect-ratio="1:4"
                    eager
                  ></v-img>
                </v-card>
              </v-app>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-else
      style="margin-top: 20px !important; margin-bottom: 20px !important"
    >
      <v-col cols="12" style="padding: 0px">
        <v-carousel
          cycle
          :show-arrows-on-hover="true"
          :hide-delimiter-background="true"
          delimiter-icon="mdi-minus"
          height="100%"
        >
          <v-carousel-item
            eager
            v-for="(image, index) of totalPackageImages"
            v-bind:key="index"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <v-img
              :src="image.asset_url"
              :lazy-src="image.asset_url"
              eager
              @click="navigateToPackageDetails()"
            />
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    packageImages: Array,
  },
  data: () => {
    return {
      firstAsset: require('../assets/img/pic1.jpg'),
      totalPackageImages: [],
    };
  },
  computed: {
    ...mapState(['backRouterURI']),
    availWidth() {
      return window.screen.width;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const totalPackageImages = [...this.packageImages];
      // For first asset
      if (totalPackageImages.length > 0) {
        this.firstAsset = totalPackageImages[0].asset_url;
        totalPackageImages.shift();
      }
      this.totalPackageImages = totalPackageImages;
    },
  },
};
</script>

<style scoped></style>
