<template>
  <div>
    <div class="loader-center" v-if="preloader">
      <div class="atom-spinner">
        <div class="spinner-inner">
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-line"></div>
          <div class="spinner-circle">
            <img src="../assets/img/logo-01.png" class="logostyle" alt="..." />
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden" v-else>
      <v-sheet>
        <v-card class="bgdisplay">
          <v-img
            class="white--text align-end"
            img
            src="../assets/img/b44.jpg"
            lazy-src="../assets/img/b44.jpg"
            height="700px"
            eager
          >
            <div class="bgdisplay2">
              <h1 style="font-weight: 400">
                Looking for a vacation with an ethnic touch? <br />Perfect
              </h1>
            </div>
          </v-img>
        </v-card>
        <br />
        <br />
        <v-card
          class="mx-auto rounded-card style67"
          color="white"
          height="90"
          width="900"
          style="display: flex"
        >
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 10px;
            "
          >
            <v-row style="max-width: 100%">
              <v-col style="padding-top: 26px">
                <v-combobox
                  solo
                  color="yellow"
                  :items="countries"
                  item-text="name"
                  item-value="name"
                  :search-input.sync="search"
                  hide-selected
                  label="Location"
                  append-icon="mdi-earth"
                  persistent-hint
                  @change="getCitiesByCountry($event)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching
                          <strong style="margin-left: 5px">
                            {{ search }}</strong
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
              <v-col style="padding-top: 26px">
                <v-combobox
                  solo
                  color="yellow"
                  :items="selectedCities"
                  item-text="name"
                  item-value="name"
                  hide-selected
                  label="City "
                  append-icon="mdi-earth"
                  persistent-hint
                  @change="getCityName($event)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          No results matching
                          <strong style="margin-left: 5px">
                            {{ search }}</strong
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
              <v-col style="padding-top: 26px; text-align: center">
                <v-menu
                  ref="startMenu"
                  :close-on-content-click="false"
                  offset-y
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-text-field
                        style="width: 220px; margin: auto"
                        solo
                        v-model="dateRangeText"
                        label="Check In / Out"
                        append-icon="mdi-calendar"
                        readonly
                        color="yellow darken-1"
                      ></v-text-field>
                    </div>
                  </template>

                  <v-date-picker
                    color="amber darken-1"
                    v-model="dateRange"
                    :min="new Date().toISOString().substr(0, 10)"
                    no-title
                    scrollable
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="amber darken-1"
                      @click="$refs.startMenu.isActive = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="amber darken-1"
                      @click="$refs.startMenu.save(dateRange)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col class="stylebutton" style="padding-top: 24px">
                <v-btn
                  width=" 190px"
                  height="55px"
                  rounded
                  outlined
                  class="mt-1 stylebutton3 srchstyl"
                  @click="searchCountry"
                >
                  search
                  <v-icon> mdi-magnify </v-icon>
                </v-btn>

                <v-overlay :value="overlay">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </v-col>
            </v-row>
          </div>
        </v-card>

        <br />
      </v-sheet>
      <br />
      <div v-if="countryResp.length === 0">
        <v-container>
          <h1 class="title-dashboard">Explore Travel Destinations</h1>
          <v-row class="fill-height" align="center" justify="center">
            <v-slide-group
              class="pa-4"
              active-class="success"
              :show-arrows="availWidth > 600 ? true : false"
            >
              <v-slide-item
                v-for="(item, index) in packageImages"
                v-bind:key="index"
              >
                <v-card
                  style="background: transparent"
                  elevation="0"
                  class="ma-4"
                  @click="navigateToExplore(item.country_name)"
                >
                  <div class="text-center">
                    <img
                      :src="item.country_assets"
                      :lazy-src="item.country_assets"
                      class="thumbnailImages"
                      alt="..."
                    />
                    <h5
                      style="
                        margin-top: 6px;
                        margin-bottom: -2px;
                        font-size: 14px;
                        letter-spacing: 0.5px;
                      "
                    >
                      {{ item.country_name }}
                    </h5>
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-row>
        </v-container>

        <v-container>
          <h1 class="title-dashboard">Explore Fun and Learning Activities</h1>
          <p class="text-center">
            Unique activities with local experts – in person or online.
          </p>
          <br /><br />
          <tb-explore-fun-and-learning></tb-explore-fun-and-learning>
        </v-container>

        <v-container>
          <v-card class="bg style33" style="border-radius: 5px" elevation="2">
            <div class="row text-left">
              <div class="col-md-6">
                <h3>Try Hosting</h3>
                <p>
                  Earn extra income and unlock new opportunities by sharing your
                  space.
                </p>
                <br />
                <button type="button" class="btn btnstyle">
                  <a :href="adminLink" id="textcol"> Learn More </a>
                </button>
              </div>
              <div class="col-md-6"></div>
            </div>
          </v-card>
        </v-container>

        <v-container>
          <h1 class="title-dashboard" style="font-weight: 400">
            Stay and Experience
          </h1>
          <br />
          <tb-stay-and-experience :packageImages="[]"></tb-stay-and-experience>
        </v-container>

        <div>
          <v-btn
            v-scroll="onScroll"
            v-show="fab"
            fab
            dark
            fixed
            bottom
            right
            color="yellow darken-1"
            @click="toTop"
          >
            <v-icon>keyboard_arrow_up</v-icon>
          </v-btn>
        </div>
      </div>
      <div style="text-align: center" v-if="responseCode !== ''">
        <h2>
          {{ message }}
        </h2>
      </div>

      <div v-if="countryResp.length !== 0">
        <div v-for="item in countryResp" v-bind:key="item.country">
          <v-card
            class="rounded cardhover positionbtn"
            style="border-radius: 10px; margin: 20px"
            @click="navigateToPackageDetails(item._id)"
          >
            <v-row>
              <v-col lg="4" sm="12">
                <v-container style="max-width: 700px">
                  <v-carousel
                    cycle
                    style="height: 280px"
                    class="imgstyle"
                    show-arrows-on-hover
                    hide-delimiter-background
                  >
                    <v-carousel-item
                      v-for="(image, imageindex) in item.assets"
                      v-bind:key="imageindex"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    >
                      <v-img
                        :src="image.asset_url"
                        :lazy-src="image.asset_url"
                        height="100%"
                        eager
                        aspect-ratio="1:4"
                      />
                    </v-carousel-item>
                  </v-carousel>
                </v-container>
              </v-col>

              <v-col class="textspace" lg="4" sm="12">
                <h2 class="exploretitle">{{ item.package_title }}</h2>
                <br />
                <h5 class="exploreclr exploremobile">
                  {{ item.pick_up_location }}
                </h5>
                <h5 class="exploreclr exploremobile">
                  {{ item.destination }}
                </h5>
                <br />
                <div
                  class="exploreclr"
                  v-if="item.bed_details[0].single_bed == true"
                >
                  <v-row class="exploremobile">
                    <h5 class="bedstyl">Single bed</h5>
                  </v-row>
                </div>
                <div
                  class="exploreclr"
                  v-if="item.bed_details[0].double_bed == true"
                >
                  <v-row class="exploremobile">
                    <h5 class="bedstyl">Double bed</h5>
                  </v-row>
                </div>
                <div
                  class="exploreclr"
                  v-if="item.bed_details[0].triple_bed == true"
                >
                  <v-row class="exploremobile">
                    <h5 class="bedstyl">Triple bed</h5></v-row
                  >
                </div>
                <div
                  class="exploreclr"
                  v-if="item.bed_details[0].dormitory == true"
                >
                  <v-row class="exploremobile">
                    <h5 class="bedstyl">Dormitory</h5></v-row
                  >
                </div>
                <div class="exploreclr" v-if="item.facilities.length > 0">
                  <v-row class="exploremobile">
                    <h5 class="bedstyl">
                      {{ _.join(item.facilities, ', ') }}
                    </h5></v-row
                  >
                </div>
                <br />
                <h2 class="exploreprice exploremobile">
                  <b>US${{ item.price }} / night</b>
                </h2>
              </v-col>
            </v-row>

            <v-btn
              class="mx-2 iconstyle"
              style="position: absolute; right: 5px !important; top: 5px"
              outlined
              fab
              color="red"
            >
              <v-icon> favorite_border</v-icon>
            </v-btn>
            <div v-if="item.property.length > 0">
              <v-btn
                style="position: absolute; right: 4px !important; bottom: 5px"
                depressed
                color="yellow darken-1"
              >
                <h5>
                  {{ item.property[0].property_type }}
                </h5>
              </v-btn>
            </div>
          </v-card>
        </div>
      </div>
      <br /><br />
      <tb-footer></tb-footer>
    </div>
  </div>
</template>

<script>
const _ = require('lodash');
import msgHelper from '../../utils/msg-helper.js';
import moment from 'moment';
import Footer from '../components/FooterEl.vue';
import ExploreFunAndLearning from '../components/ExploreFunAndLearning.vue';
import { mapState } from 'vuex';
import ImageELVue from '../components/ImageEL.vue';

export default {
  components: {
    'tb-footer': Footer,
    'tb-stay-and-experience': ImageELVue,
    'tb-explore-fun-and-learning': ExploreFunAndLearning,
  },
  data() {
    return {
      dates: [],
      city: '',
      packageImages: [],
      country1: '',
      countries: [],
      courtryCodes: [
        { code: '+91', title: 'India(+91)' },
        { code: '+1', title: 'USA(+1)' },
      ],
      overlay: false,
      e: '',
      error: '',
      responseCode: '',
      message: '',
      countryResp: [],
      country: '',

      preloader: false,
      fab: false,
      dateStart: [],
      dateEnd: 0,

      trip: {
        name: '',
        location: null,
        start: null,
        end: null,
      },
      selectedCities: [],

      search: null,
      number: '',
      e6: 1,
      step: 1,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      date2: new Date(Date.now() - new Date().getTimezoneOffset() * 70000)
        .toISOString()
        .substr(0, 10),
      mobile: '',
      mobileCode: '',
      login: 0,

      otp: '',
      menu: false,
      modal: false,
      adult: 0,
      children: 0,
      infant: 0,
      menu2: false,
      dialog50: false,
      dialog: false,
      otpBasedDialog: false,
      dialog4: false,
      mobileDialCode: '',
      dateRange: [],
      adminLink: '',
    };
  },
  watch: {
    model(val) {
      if (val.length > 5) {
        this.$nextTick(() => this.model.pop());
      }
    },
  },
  computed: {
    availWidth() {
      return window.screen.width;
    },
    dateRangeText() {
      return this.dateRange.join(' - ');
    },
    ...mapState({
      user: (state) => state.user,
      token: (state) => state.token,
    }),
  },
  created() {
    document.title =
      'TuroBuddy | Holiday Rentals, Cabins, Beach Houses, Unique Homes & Experiences';
    this.fetchEventsList();
  },
  mounted() {
    const isProd = process.env.VUE_APP_BUILD_MODE === 'prod';
    this.adminLink = isProd
      ? process.env.VUE_APP_PROD_ADMIN
      : process.env.VUE_APP_STAGING_ADMIN;
  },
  methods: {
    async fetchEventsList() {
      this.preloader = true;
      try {
        const resp = await this.$http.post('fetchPackageLocation');
        this.packageImages = resp.data.data;
        this.countries = _.map(resp.data.data, (country) => {
          let payload = {
            name: country.country_name,
            states: _.map(country.city_name, (city) => {
              return { name: city };
            }),
          };
          return payload;
        });
      } catch (e) {
        msgHelper.showToast(
          'error',
          'Failed to get the locations, please try again later'
        );
      }
      this.preloader = false;
    },
    navigateToExplore(country_name) {
      this.$router.push({
        name: 'Explore',
        params: {
          country_name,
        },
      });
    },
    navigateToPackageDetails(packageId) {
      this.$router.push({
        name: 'Package Details',
        params: {
          packageId,
        },
      });
    },
    getCitiesByCountry(event) {
      this.country = '';
      this.country = event.name;
      this.selectedCities = event.states;
    },
    getCityName(event) {
      this.city = '';
      this.city = event.name;
    },
    async searchCountry() {
      this.overlay = !this.overlay;
      // START DATE
      let start_date = '';
      const startDate = this.dateRange[0] ? this.dateRange[0] : null;
      if (startDate) {
        start_date = moment(startDate, 'YYYY-MM-DD');
        start_date = moment(startDate).unix();
      }
      // END DATE
      let end_date = '';
      const endDate = this.dateRange[1] ? this.dateRange[1] : null;
      if (endDate) {
        end_date = moment(endDate, 'YYYY-MM-DD');
        end_date = moment(end_date).unix();
      }
      this.overlay = true;
      try {
        const payloadObj = {
          country: this.country,
          city: this.city,
          check_in_date: start_date,
          check_out_date: end_date,
          amenities: [],
          deleted_at: 0,
          facilities: [],
          property_type: [],
        };
        const response = await this.$http.post('searchPackage', payloadObj);
        const responseMsg = response.data.message;

        if (response.status === 200) {
          let query = {};
          if (responseMsg === 'List of Packages Found') {
            query.search = 1;
          } else if (
            responseMsg ===
              'No Package Found! Here are Some Recommended Packages For You' ||
            responseMsg === 'Recommanded Packages Found'
          ) {
            query.search = 2;
          } else if (
            responseMsg === 'List of Check_in,Check_out packages found'
          ) {
            query.search = 3;
          } else {
            query.search = 0; // If not searched
            return;
          }
          const packages = response.data.data;
          this.$store.commit('setPackages', packages);

          this.$router.push({
            name: 'Explore',
            params: { country_name: this.country },
            query,
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.overlay = false;
    },
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style scoped src="../assets/css/style.css"></style>
